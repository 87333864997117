import React from "react";
import { useQuery } from "react-query";

import { getAllUsers } from "$utils/queryFunctions";

import Heading from "$ui/heading";
import Loader from "$ui/loader";
import Table from "$ui/table";

import AdminLayout from "$components/layout/admin";
import EventsPanel from "$components/events/eventsPanel";

const UsersListPage = () => {
  const { data: users, isLoading } = useQuery("users", getAllUsers);

  return (
    <AdminLayout title="My Complaints">
      <section className="p-10 phone:px-5 h-screen overflow-y-scroll">
        <Heading variant="tertiary-left">Users signed up on CRO</Heading>
        {isLoading ? (
          <Loader />
        ) : (
          <Table
            className="mt-6"
            headers={["full name", "email", "phone", "state", "city"]}
          >
            {users.map((u) => (
              <tr key={u.id}>
                <td>{u.fullName}</td>
                <td>{u.email}</td>
                <td>{u.phone}</td>
                <td>{u.state}</td>
                <td>{u.city}</td>
              </tr>
            ))}
          </Table>
        )}
      </section>

      {/* <section className='bg-white py-10 px-4'>
        <Heading variant='tertiary-left'>Upcoming events</Heading>
        <EventsPanel />
      </section> */}
    </AdminLayout>
  );
};

export default UsersListPage;
